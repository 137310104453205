import { Component, Vue } from "vue-property-decorator";
import IViewMixin from "@/mixins/interface/IViewMixin";

@Component({})
export default class ViewMixin extends Vue implements IViewMixin {
  protected name = "ViewMixin.ts";

  onRemove(
    itemId: number,
    resource: string,
    redirectRouteNameOnDeleteSuccess: string,
    resourceHumanName = ""
  ): void {
    this.$dialogsService
      .deleteConfirmDialog(
        resourceHumanName !== "" ? resourceHumanName : resource
      )
      .then((resourceName: string) => {
        this.removeAction({
          id: itemId,
          resource: resource,
        }).then((response: any) => {
          /*
          new CrudRemovedSuccess().dispatch({
            id: itemId,
            resource: resource,
            resourceName: resourceName,
          });

           */
          if (this.$route.name !== redirectRouteNameOnDeleteSuccess) {
            this.$router.replace({ name: redirectRouteNameOnDeleteSuccess });
            return;
          }
          this.onRemoveSuccess(response, resourceName);
        });
      });
  }

  removeAction(options: any): Promise<any> {
    console.warn(
      "This is removeAction in ViewMixin. Implement removeAction function to your view!"
    );
    return new Promise<any>(() => {});
  }

  protected onRemoveSuccess(response: any, resourceName: string): void {
    console.warn(
      "This is onRemoveSuccess in ViewMixin. Implement function to your view!",
      response,
      resourceName
    );
  }
}
