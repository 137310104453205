
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import ViewMixin from "@/mixins/Crud/ViewMixin";
import DefaultLayout from "@/layout/DefaultLayout.vue";
import { Machine } from "@/config/Modules";

const MachineModule = namespace(Machine.store);

@Component({
  components: { DefaultLayout },
  mixins: [ViewMixin],
})
export default class MachineView extends Vue {
  //private name = "MachineView";

  protected resource = Machine.resource;
  protected descriptionField = Machine.description_field;
  protected redirectRouteNameOnDeleteSuccess = "BaseDataMachineList";

  @MachineModule.Action("del")
  protected removeAction: any;

  protected onAdd(): void {
    this.$router.push({ name: "BaseDataMachineCreate" });
  }
}
